export const environment = {
  production: false,
  staging: true,
  apiEndpoint: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '789647ad-f923-4fb9-a4b8-baef551e4df0',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '9ec171e8-9609-4f5c-b9e3-cea4f35862f5',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.staging.services4eo.com/'
};
